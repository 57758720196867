import React, { Component } from "react"
import { Link, navigate } from "gatsby"

// Libs
import anime from "animejs/lib/anime.es.js"

// Layout
import { Row, Cell, View, Panel } from "~layout/Layout"

// Styles
import Styles from "./QuestionEnd.module.styl"

// Store
import QuestionStore from "~stores/QuestionStore"

class QuestionEnd extends Component {
    constructor(props) {
        super(props)

        // Refs
        this.loader = React.createRef()
        this.loaderMiniBar = React.createRef()
    }

    state = {
        results: false,
        isDebug: false,
        loaderPoints: "",
    }

    componentDidMount() {
        this.checkIfEverAnswered()
        this.computeScores()
        this.setAnimation()
    }

    checkIfEverAnswered() {
        if (!QuestionStore.checkIfEverAnswered()) {
            navigate(`/questions/1`)
            return
        }
    }

    computeScores() {
        this.setState({
            results: QuestionStore.computeScores(),
        })
    }

    setAnimation() {
        this.interval = setInterval(() => {
            let loaderString = this.state.loaderPoints

            if (loaderString.length <= 2) {
                loaderString += "."
            } else {
                loaderString = ""
            }

            this.setState({
                loaderPoints: loaderString,
            })
        }, 300)

        anime.set(this.loader.current, {
            opacity: 0,
            translateX: "-100%",
        })

        anime({
            targets: this.loaderMiniBar.current,
            direction: "reverse",
            loop: true,
            rotate: "360deg",
            easing: "linear",
            duration: 600,
        })

        anime({
            targets: this.loader.current,
            keyframes: [
                {
                    opacity: 1,
                    translateX: "-100%",
                },
                { translateX: "-70%" },
                { translateX: "-60%" },
                { translateX: "-20%" },
                { translateX: "0" },
                { translateX: "0" },
                { translateX: "0" },
            ],
            delay: 100,
            duration: 6000,
            easing: "easeInOutQuad",
            update: (anim) => {
                if (anim.progress === 100) {
                    clearInterval(this.interval)
                    this.redirectToCandidatePage()
                }
            },
        })
    }

    redirectToCandidatePage() {
        navigate(`/sites/${this.state.results.candidatePage}`)
    }

    render() {
        return (
            <View name="end" theme="saumon">
                <div className={Styles.QuestionEnd}>
                    <div className={Styles.QuestionEnd__inner}>
                        <div className={Styles.QuestionEnd__body}>
                            <Row full={true}>
                                <Cell start="0" end="12">
                                    <div
                                        className={
                                            Styles.QuestionEnd__body__inner
                                        }
                                    >
                                        <h1
                                            className={`heading-4 ${Styles.QuestionEnd__body__title}`}
                                        >
                                            On y est presque !
                                        </h1>
                                    </div>
                                </Cell>
                            </Row>
                        </div>
                        <div
                            className={Styles.QuestionEnd__loader}
                            ref={this.loader}
                        >
                            <Row>
                                <Cell start="0" end="12">
                                    <div
                                        className={
                                            Styles.QuestionEnd__loader__inner
                                        }
                                    >
                                        <span
                                            className={`teasing-1 ${Styles.QuestionEnd__loader__title}`}
                                        >
                                            Nous calculons vos
                                            résultats&nbsp;&nbsp;
                                            <span
                                                ref={this.loaderMiniBar}
                                                className={`${Styles.QuestionEnd__loader__rotatingbar}`}
                                            >
                                                —
                                            </span>
                                            &nbsp;&nbsp;
                                            <span
                                                class={`${Styles.QuestionEnd__loader__points}`}
                                            >
                                                {this.state.loaderPoints}
                                            </span>
                                        </span>
                                    </div>
                                </Cell>
                            </Row>
                        </div>
                    </div>
                </div>
            </View>
        )
    }
}

export default QuestionEnd
